import React, { Component } from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'

import {
    Breadcrumb, 
    Row, Col, Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import { getCache } from '../../util/get-cache-handler'
import { getTrans } from '../../util/get-trans-handler'
import { getAxiosArmies } from '../../util/get-axios-armies-handler'
import DivisionList from '../../components/map/divisions_list'


import Layout from '../../components/layout'
// import LocalImage from '../../components/local-image'
// import Share from '../../components/share'


// import '../../css/divisions-list.css'



class DivisionsListPage extends Component {

    constructor(props, context) {
        super(props, context);
        // Don't call this.setState() here!

        this.state = {
            trans: [],
            divisionsList: [],
        }

    }

    async componentDidMount() {

        let self = this

        /////// Initial translate for slug //////////
        const enList = (self.props.data.en.edges)
        // console.log( enList )  
        getTrans(enList).then(t => {
            self.setState({trans: t})
        })

        /////// Setup Data //////////
        let armieslist = JSON.parse(localStorage.getItem('armieslist')) || undefined

        // Get Local Cache
        if ( typeof armieslist === 'undefined' || getCache() ) {
            console.log(`load ajax`)
            armieslist = await getAxiosArmies({ o:1000 })

            localStorage.setItem('armieslist', JSON.stringify(armieslist))
            localStorage.setItem('version', +(new Date()))
        }

        /////// Display Data //////////
        if ( armieslist ){
            let divisionslist = _.reject( armieslist, {'TypeName':'Faction'} )
            console.log(`==== divisionslist ====`,divisionslist)
        
            self.setState({divisionsList: divisionslist})
        }



    }

    render() {

        // let _t = this.state.trans
        // console.log(`this.props.item =  `)
        console.log( this.props )

        return (
            <NamespacesConsumer>
            {(t, { i18n }) => {
                const lang = i18n.language.toLowerCase()
                // console.log(i18n)
                // console.log(lang)

                return (
                    
                    <Layout  key={`division-layout`} siteData={ { title:`${t("MU000")} | ${t("MU005")} | ${t("MU008")} | ${t("PT6100")}`, url:this.props.location.href } }>
                        <div id="DivisionList" className="body-page">
                            <Container className="wrapper line-head">
                                <Breadcrumb>
                                    <Breadcrumb.Item href={`/${lang}/map/`}> {t('MU008')} ></Breadcrumb.Item>
                                </Breadcrumb>
                            </Container>

                            <main role="main" className="main">
                                <Container className="wrapper">
                                    <Row >
                                        <Col className="ptitle">
                                            {t('PT6100')}
                                        </Col>
                                    </Row>
                                </Container>

                                <Container className="line-base">
                                    <DivisionList divisionslist={this.state.divisionsList} trans={this.state.trans} />
                                </Container>

                            </main>
                            
                        </div>
                    </Layout>
                )
            }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(DivisionsListPage)

export const query = graphql`
    query getDivisionsListPageLang($lng: String!) {
        locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }

        en: allLocale(filter: { lng: { eq: "en", }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }
    }
`
